<template>
  <footer class="custom-footer bg-dark py-2 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 mt-5">
          <div>
            <div>
              <img src="@/assets/images/logo-light.png" alt="logo light" height="32">
            </div>
            <div class="mt-4 fs-13">
                <a :href='$t("t-doc-terms-and-conditions_path")' style="color: white;" target="_blank">
                  <span style="text-decoration: underline;">{{ $t("t-doc-terms-and-conditions") }}</span>
                </a>
                &nbsp;&nbsp;
                <a :href='$t("t-doc-privacy-policy_path")' style="color: white;" target="_blank">
                   <span style="text-decoration: underline;">{{ $t("t-doc-privacy-policy") }}</span>
                </a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 ms-lg-auto">
          <div class="row">
            <div class="col-sm-6 mt-4">
              <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                <li class="nav-item">
                  <a class="nav-link active" style="color: white;" href="#about">{{ $t("t-menu-about") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" style="color: white;" href="#advantages">{{ $t("t-menu-advantages") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" style="color: white;" href="#features">{{ $t("t-menu-features") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" style="color: white;" href="#plans">{{ $t("t-menu-plans") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" style="color: white;" href="#faqs">{{ $t("t-menu-faqs") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" style="color: white;" href="#contact">{{ $t("t-menu-contact") }}</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 mt-4">
            </div>
          </div>
        </div>

        <div class="col-lg-3 ms-lg-auto">
          <div class="row">
<!--            <img src="@/assets/images/google-play.png" alt=""  />-->
<!--            <img src="@/assets/images/app-store.png" alt=""  />-->
          </div>
        </div>


      </div>

      <div class="row text-center text-sm-start align-items-center mt-2">
        <div class="col-sm-6">
          <div>
            <p class="copy-rights mb-0 currentyear"></p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end mt-3 mt-sm-0">
            <ul class="list-inline mb-0 footer-social-link">
              <li class="list-inline-item">
                <a href="https://t.me/+aJCtvqSPtNxmMmZk" class="avatar-xs d-block">
                  <div class="avatar-title rounded-circle">
                    <i class="ri-telegram-fill"></i>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {localeComputed} from "@/state/helpers";

export default {
  name: 'Footer',
  components: {
  },

  methods: {
  },
  computed: {
    ...localeComputed,
  },
  mounted() {
  }
}
</script>
